import { Typography } from '@mui/material';
import './content.css';
import {ImageText, TextImage} from '../personal_util/components';

export function Projects(props) {
  const style = {fontSize: "max(1.5vw, 15px)", fontFamily: "'Annie Use Your Telescope'", color: "#000", marginLeft: "30px"};
  const projects = {
    'ployem': `A Collection of open source projects that were the result of months of interviews and study of the needs of highschool students from under represented communities`,
    'msa': `A website for the MIT Muslim Student Association to showcase thier information and events. Over 3000 visits monthly.`,
    'starFront': `A single player online game, completed along side 2 of my friends over the course of 2 weeks`,  
    'iLab': `A free and open source interpreter lab, inspired by jupyter notebook, supporting python, javascript, latex, and more`,
    'apeGang': `A shared place for a group of friends who like to make stuff to be able to share our projects, this is currently under development`,
    'simba': `I was looking for a major, wanted to explore 3D printing, and had a great friends ready to help. So over IAP, we created a team and put together 2 3D printers -- Resputen for my friend and Simba for me.`,
    'stardust': `A Clash of Clans themed Minecraft server 2 friends and I are working on developing. Developed server from scratch and created over 10 plugins for it.`
  }

  return (
    <div style={{color: "black", height: "91%"}} id="projects" className="none column align-center scroll no-scrollbar">
      {/*** Stardust ***/}
      <ImageText image={props.images("./projects/project5.png")} style={style} name={"Stardust"} text={projects['stardust']} onClick={() => {window.location = ""}}/>

      {/*** MIT MSA ***/}
      <TextImage image={props.images("./projects/project1.png")} style={style} name={"MIT MSA"} text={projects['msa']} onClick={() => {window.location = "https://www.mit-msa.com"}}/>
      
      {/*** Ployem iLab ***/}
      <ImageText image={props.images("./projects/project0.png")} style={style} name={"iLab"} text={projects['iLab']} onClick={() => {window.location = "https://ployem.com/ilab"}}/>

      {/*** Star Front ***/}
      <TextImage image={props.images("./projects/project2.png")} style={style} name={"StarFront"} text={projects['starFront']} onClick={() => {window.location = "https://ployem.com/glab/starfront/?puzzleId=1"}}/>

      {/*** Simba ***/}
      <ImageText image={props.images("./projects/project4.png")} style={style} name={"Simba"} text={projects['simba']}/>
    </div>
  )
}
