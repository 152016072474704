import './content.css';
import { Typography } from '@mui/material';
import {ImageText, TextImage} from '../personal_util/components';

export function Research(props) {
  const style = {fontSize: "max(1.5vw, 15px)", fontFamily: "'Annie Use Your Telescope'", color: "#000", marginLeft: "30px"};
  const research = {
    'hcie': `Second author on published FA3DS paper. Worked in the lab of Professor Stefanie Muller under Phd candidate Faraz Faruqi to helped investigate functionality awareness for 3D models. FA3DS was recently on MIT news.`,
    'physics': `Worked under Professor Markus Klute to model benefits of new HF nose. Was on a team of 4 met once a week to collaborate and produce code simulating expected benefits of new HF nose.`
  }

  return (
    <div style={{color: "black", height: "91%"}} id="research" className="none column align-center scroll no-scrollbar">
      {/*** HCIE ***/}
      <ImageText image={props.images("./research/research0.png")} style={style} name={"Computer Science | FA3DS"} text={research['hcie']}/>

      {/*** Physics ***/}
      <TextImage image={props.images("./research/research1.png")} style={style} name={"Physics"} text={research['physics']}/>
    </div>
  )
}
